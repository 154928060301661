import { useEffect, useState } from "react";
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase'; // Import Firebase configuration
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import TertiaryButton from '../components/TertiaryButton';
import submergeLogo from '../assets/submerge-logo.png';
import * as Icon from 'react-feather';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { signInWithEmailAndPassword } from 'firebase/auth'; // Import Firebase authentication
import { auth } from '../firebase'; // Import Firebase configuration

import styles from '../styles/SignIn.css';

function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(''); // State for email
  const [password, setPassword] = useState(''); // State for password
  const [error, setError] = useState(''); // State for error message
  const [loading, setLoading] = useState(false); // State for loading state
  const navigate = useNavigate(); // Hook to programmatically navigate

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to check if email is in authorizedUsers
  const checkEmailAuthorization = async () => {
    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      const docSnapshot = await getDoc(authorizedUsersRef);
  
      if (docSnapshot.exists()) {
        const authorizedUsersData = docSnapshot.data();
        const authorizedEmails = authorizedUsersData.beta || []; // Access the 'beta' array
  
        // Check if the entered email exists in the 'beta' array
        return authorizedEmails.includes(email);
      }
      return false;
    } catch (error) {
      console.error('Error checking email authorization:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Check if the email is authorized
    const isAuthorized = await checkEmailAuthorization();
    
    if (!isAuthorized) {
      setError('The site is not currently open to the public. Your email is not authorized.');
      setLoading(false);
      return;
    }

    try {
      // Authenticate with Firebase if the email is authorized
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      console.log('User signed in successfully:', user);
      // Navigate to profile page after successful sign-in
      navigate('/');
    } catch (err) {
      setError('Oops! Email or password might be incorrect. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="sign-in">
      <div className="sign-in-container">

        <div className="left-panel">
          <div className="logo">
            <img className="submerge-logo" src={submergeLogo} alt="Submerge Logo" />
            <p className="tagline">UNDERGROUND MUSIC NETWORK</p>
          </div>
        </div>

        <div className="right-panel">
          <div className="sign-in-card">
            <div className="card-header">
              <h5 className="card-title">Welcome back!</h5>
              <h1 className="card-title">Sign In</h1>
            </div>

            {/* SIGN IN FORM START */}
            <form className="sign-in-form" onSubmit={handleSubmit}>
              {error && (
                <div className="error-message">
                  <p className="error-text">{error}</p>
                </div>
              )}

              <div className="input-fields-section">
                <div className="form-email form-field">
                  <label className="label">EMAIL</label>
                  <input
                    className="form-input"
                    type="email"
                    placeholder="you@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update email state
                    required
                  />
                </div>

                <div className="form-password form-field">
                  <label className="label">PASSWORD</label>
                  <div className="password-field">
                    <input
                      className="form-input"
                      placeholder="Enter your password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)} // Update password state
                      required
                    />
                    <div className="toggle-password-icon" onClick={togglePasswordVisibility}>
                      {showPassword ? <Icon.EyeOff size={18} /> : <Icon.Eye size={18} />}
                    </div>
                  </div>
                </div>

                <div className="rememberme-forgotpass">
                  <div className="rememberme">
                    <input type="checkbox" id="rememberme" className="remember-me-checkbox" />
                    <label htmlFor="rememberme" className="remember-me-label">Remember me</label>
                  </div>

                  <div className="forgotpassword">
                    <Link to="/forgot-password" className="forgotpassword-label">Forgot Password?</Link>
                  </div>
                </div>
              </div>

              <div className="form-buttons">
                <PrimaryButton buttonLabel={loading ? 'Signing in...' : 'Sign in'} className="button" disabled={loading} />
              </div>

              <div className="switch-form-texts">
                <p>Don't have an account?</p>
                <Link to="/signup" className="link-to-signup">Click here to sign up!</Link>
              </div>
            </form>
            {/* SIGN IN FORM END */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase'; // Import Firebase auth instance

import './App.css';
import Admin from './screens/Admin';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import MainScreen from './screens/MainScreen';
import Profile from './screens/Profile';
import AccountSettings from './screens/AccountSettings';
import Notifications from './screens/Notifications';
import Messages from './screens/Messages';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe(); // Clean up on unmount
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Loading state while authentication is being determined
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <MainScreen /> : <Navigate to="/signin" />}
        />
        <Route path="/admin" element={<Admin />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        {/* Dynamic Profile Route */}
        <Route path="/:handle" element={isAuthenticated ? <Profile /> : <Navigate to="/signin" />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/profile" element={isAuthenticated ? <MainScreen /> : <Navigate to="/signin" />} />
        <Route path="/profile/id" element={isAuthenticated ? <Profile /> : <Navigate to="/signin" />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/edit_profile" element={isAuthenticated ? <AccountSettings /> : <Navigate to="/signin" />} />
        <Route path="/notifications" element={isAuthenticated ? <Notifications /> : <Navigate to="/signin" />} />
      </Routes>
    </div>
  );
}

export default App;
import { useEffect, useState } from "react";
import styles from '../styles/NewsfeedCard.css';
import BubblePill from './BubblePill';
import SinglePostPopup from './SinglePostPopup';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { db, storage } from '../firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, serverTimestamp, onSnapshot, doc, getDoc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

function ProfileNewsFeedCard({ passedUID }) {
  const [posts, setPosts] = useState([]);
  const [userData, setUserData] = useState(null);
  const [commentCounts, setCommentCounts] = useState({});
  const [likeCounts, setLikeCounts] = useState({});
  const [isLiked, setIsLiked] = useState({});
  const [popupVisibleForPost, setPopupVisibleForPost] = useState(null);
  const [commentContent, setCommentContent] = useState('');
  const [isSinglePostPopupVisible, setIsSinglePostPopupVisible] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const openSinglePostPopup = (post) => {
    setSelectedPost(post);
    setIsSinglePostPopupVisible(true);
  };

  const closeSinglePostPopup = () => {
    setIsSinglePostPopupVisible(false);
    setSelectedPost(null);
  };

  const toggleLike = async (postId) => {
    try {
      const likesRef = collection(db, 'posts', postId, 'likes');
      const likeDocRef = doc(likesRef, passedUID);
      const likeDoc = await getDoc(likeDocRef);

      if (likeDoc.exists()) {
        await deleteDoc(likeDocRef);
        setLikeCounts((prev) => ({ ...prev, [postId]: (prev[postId] || 1) - 1 }));
      } else {
        await setDoc(likeDocRef, {
          userID: passedUID,
          postID: postId,
          timestamp: serverTimestamp(),
        });
        setLikeCounts((prev) => ({ ...prev, [postId]: (prev[postId] || 0) + 1 }));
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const addComment = async (postId) => {
    try {
      if (commentContent.trim()) {
        const commentsRef = collection(db, 'posts', postId, 'comments');
        await addDoc(commentsRef, {
          userID: passedUID,
          postID: postId,
          timestamp: serverTimestamp(),
          content: commentContent,
        });
        setCommentContent('');
        setPopupVisibleForPost(null);
        fetchCommentCount(postId);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const fetchCommentCount = async (postId) => {
    try {
      const commentsRef = collection(db, 'posts', postId, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      setCommentCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: commentsSnapshot.size
      }));
    } catch (error) {
      console.error('Error fetching comment count:', error);
    }
  };

  const fetchLikes = (postId) => {
    const likesRef = collection(db, 'posts', postId, 'likes');
    return onSnapshot(likesRef, (snapshot) => {
      setLikeCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: snapshot.size
      }));
      setIsLiked((prevLikes) => ({
        ...prevLikes,
        [postId]: snapshot.docs.some((doc) => doc.data().userID === passedUID)
      }));
    });
  };

  const fetchUserData = async () => {
    try {
      const userDocRef = doc(db, 'users', passedUID);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const fetchedUserData = userDoc.data();

        let userProfilePic = fetchedUserData.profilePic || profilePlaceholder;
        if (userProfilePic.startsWith('gs://')) {
          const profilePicRef = ref(storage, userProfilePic);
          userProfilePic = await getDownloadURL(profilePicRef);
        }

        setUserData({
          userHandle: fetchedUserData.handle || 'Unknown User',
          userName: fetchedUserData.userName,
          profilePic: userProfilePic,
        });
      } else {
        console.error('No user found for the provided UID');
        setUserData(null);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUserPosts = async () => {
    try {
      if (!userData) return;

      const postsRef = collection(db, 'posts');
      const q = query(postsRef, where('userID', '==', passedUID));
      const querySnapshot = await getDocs(q);

      const postsWithHandles = await Promise.all(
        querySnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();
          fetchCommentCount(postDoc.id);
          const unsubscribeLikes = fetchLikes(postDoc.id);

          return {
            id: postDoc.id,
            unsubscribeLikes,
            ...postData,
            userProfilePic: userData.profilePic,
            userHandle: userData.userHandle,
          };
        })
      );

      setPosts(postsWithHandles);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [passedUID]);

  useEffect(() => {
    if (userData) {
      fetchUserPosts();
    }
  }, [userData]);

  useEffect(() => {
    return () => {
      posts.forEach((post) => {
        if (post.unsubscribeLikes) {
          post.unsubscribeLikes();
        }
      });
    };
  }, [posts]);

  return (
    <div className="newsfeed-card component">
      {posts.length > 0 ? posts.map((post) => (
        <div key={post.id} className="newsfeed-card-container">
          <div className="user-image">
            <img src={post.userProfilePic || profilePlaceholder} alt="User profile" />
          </div>
          <div className="post-content">
            <span className="time-ago">
              {post.timestamp?.toDate ? post.timestamp.toDate().toLocaleString() : post.timestamp}
            </span>
            <Link to={`/profile/${post.userID}`} style={{ textDecorationLine: 'none' }}>
              <p className="user-handle">@{post.userHandle || 'Unknown User'}</p>
            </Link>
            <p className="content-text">{post.content}</p>

            {post.mediaURL && (
              <div className="photo-grid">
                <img src={post.mediaURL} alt="Post media" />
              </div>
            )}

            <div className="pill">
              <BubblePill bubbleText="artist" />
            </div>

            <div className="post-actions">
              <div className="post-data-count">
                <button className="comments-view" onClick={() => openSinglePostPopup(post)}>
                  <span>{commentCounts[post.id] || 0} Comments</span>
                </button>
                <span> • </span>
                <span>{likeCounts[post.id] || 0} Likes</span>
              </div>
              <div className="like-comment">
                <button onClick={() => toggleLike(post.id)} className="like-button">
                  <Icon.ThumbsUp
                    size={21}
                    fill={isLiked[post.id] ? '#1D599C' : 'none'}
                    color={isLiked[post.id] ? '#0094D4' : '#fff'}
                  />
                </button>
                <button onClick={() => setPopupVisibleForPost(post.id)} className="comment-button">
                  <Icon.MessageSquare size={22} aria-label="Comment On Post" />
                </button>
              </div>
            </div>

            {popupVisibleForPost === post.id && (
              <div className="comment-popup">
                <textarea
                  value={commentContent}
                  onChange={(e) => setCommentContent(e.target.value)}
                  placeholder="Write your comment..."
                />
                <button onClick={() => addComment(post.id)}>Submit</button>
                <button onClick={() => setPopupVisibleForPost(null)}>Cancel</button>
              </div>
            )}
          </div>
        </div>
      )) : <p>No posts available</p>}

      {isSinglePostPopupVisible && selectedPost && (
        <SinglePostPopup
          isVisible={isSinglePostPopupVisible}
          onClose={closeSinglePostPopup}
          post={selectedPost}
        />
      )}
    </div>
  );
}

export default ProfileNewsFeedCard;
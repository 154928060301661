import { useEffect, useState } from "react";
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase'; // Ensure Firebase configuration is correct
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import TertiaryButton from '../components/TertiaryButton';

import submergeLogo from '../assets/submerge-logo.png';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';

import styles from '../styles/SignUp.css';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [successMatchPassword, setSuccessMatchPassword] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false); // State for displaying the sign-up form

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [authError, setAuthError] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate the email as the user types
    if (value !== '' && !emailRegex.test(value)) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Validate the password as the user types
    if (value !== '' && !passwordRegex.test(value)) {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
    }

    // Check if confirmPassword matches the new password
    setMatchError(value !== confirmPassword || confirmPassword === '');
    setSuccessMatchPassword(confirmPassword !== '' && value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    // Check if passwords match
    setMatchError(password !== value || value === '');
    setSuccessMatchPassword(value !== '' && password === value);
  };

  const isButtonDisabled = () => {
    return (
      !email.trim() ||
      !password.trim() ||
      !confirmPassword.trim() ||
      !termsAccepted
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Function to check if email is in authorizedUsers
  const checkEmailAuthorization = async () => {
    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      const docSnapshot = await getDoc(authorizedUsersRef);

      if (docSnapshot.exists()) {
        const authorizedUsers = docSnapshot.data();
        // Check if the entered email exists as a field in the document
        return authorizedUsers.hasOwnProperty(email);
      }
      return false;
    } catch (error) {
      console.error('Error checking email authorization:', error);
      return false;
    }
  };

  // Function to handle email check before showing the sign-up form
  const handleEmailCheck = async (e) => {
    e.preventDefault(); // Prevent form submission refresh

    // Check if the email is authorized
    const isAuthorized = await checkEmailAuthorization();
    
    if (isAuthorized) {
      setAuthError(false);
      setShowSignUpForm(true); // Show the sign-up form on successful email authorization
    } else {
      setAuthError(true);
    }
  };

  return (
    <div className="sign-up">
      <div className="sign-up-container">

        <div className="left-panel">
          <div className="logo">
            <img className="submerge-logo" src={submergeLogo} />
            <p className="tagline">UNDERGROUND MUSIC NETWORK</p>
          </div>
        </div>

        <div className="right-panel">
          <div className="sign-up-card">
            <div className="card-header">
              <h1 className="card-title">Sign Up</h1>
            </div>

            {/* SIGN IN FORM START */}
            {showSignUpForm ? (
              <form className="sign-up-form" onSubmit={(e) => e.preventDefault()}>
                <div className="input-fields-section">
                  <div className="form-email form-field">
                    <label className="label">EMAIL</label>
                    <input 
                      className="form-input"
                      type="email"
                      value={email}
                      placeholder="you@example.com" 
                      onChange={handleEmailChange}
                      required
                      style={{borderBottomColor: errorEmail ? 'var(--error)' : 'var(--acc-blue)'}}
                    />
                    
                    {/* EMAIL VALIDATION ERROR */}
                    { errorEmail && 
                      <div className="input-error-message">
                        <p className="error-text">Enter a valid email address.</p>
                      </div>
                    }
                  </div>

                  <div className="form-password form-field">
                    <label className="label">PASSWORD</label>
                    <div className="password-field">
                      <input 
                        className="form-input"
                        placeholder="Enter your password" 
                        value={password}
                        onChange={handlePasswordChange}
                        type={showPassword ? 'text' : 'password'}
                        style={{borderBottomColor: errorPassword ? 'var(--error)' : 'var(--acc-blue)'}}
                      />
                      <div className="toggle-password-icon" onClick={togglePasswordVisibility}>
                        { showPassword ?
                          (
                          <Icon.EyeOff size={18} />
                          )
                          :
                          (<Icon.Eye size={18} />)
                        }
                      </div>
                    </div>
                    
                    {/* PASSWORD VALIDATION ERROR */}
                    { errorPassword && 
                      <div className="input-error-message">
                        <p className="error-text">Password must be 8+ characters, include an uppercase letter, a number, and a special character.</p>
                      </div>
                    }
                  </div>

                  <div className="form-reenter-password form-field">
                    <label className="label">CONFIRM PASSWORD</label>
                    <div className="reenter-password-field">
                      <input 
                        className="form-input"
                        placeholder="Re-enter your password" 
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        type={showConfirmPassword ? 'text' : 'password'}
                        style={{borderBottomColor: 'var(--acc-blue)'}}
                      />
                      <div className="toggle-reenter-password-icon" onClick={toggleConfirmPasswordVisibility}>
                        { showConfirmPassword ?
                          (
                          <Icon.EyeOff size={18} />
                          )
                          :
                          (<Icon.Eye size={18} />)
                        }
                      </div>
                    </div>

                    {/* CONFIRM PASSWORD VALIDATION ERROR */}
                    { matchError && confirmPassword !== '' &&
                      <div className="input-error-message">
                        <p className="error-text">Passwords don't match.</p>
                      </div>
                    }
                    { successMatchPassword &&
                      <div className="input-error-message">
                        <p className="success-text">Passwords match.</p>
                      </div>
                    }
                  </div>

                  <div className="terms-privacy">
                    <input 
                      type="checkbox" 
                      id="terms-privacy" 
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      className="terms-privacy-checkbox" />
                    <label htmlFor="terms-privacy" className="terms-privacy-label">
                      I accept the
                      <span>
                        <Link 
                          to="https://submerge.live/terms-conditions" 
                          target="_blank"
                          className="link-to-termsprivacy">Terms & Conditions</Link>
                      </span>
                      , and
                      <span>
                        <Link 
                          to="https://submerge.live/privacy-policy" 
                          target="_blank"
                          className="link-to-termsprivacy">Privacy Policy</Link>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="form-buttons">
                  <PrimaryButton buttonLabel="Sign Up" className="button" disabled={isButtonDisabled()}/>
                </div>

                <div className="switch-form-texts">
                  <p>
                    Already have an account?
                  </p>
                  <Link to="/signin" className="link-to-signin">
                    Sign in here!
                  </Link>
                </div>
              </form>
            ) : (
              // If email check not done, show email check form
              <form className="email-check-form" onSubmit={handleEmailCheck}>
                <div className="input-fields-section">
                  <div className="form-email form-field">
                    <label className="label">EMAIL</label>
                    <input 
                      className="form-input"
                      type="email"
                      value={email}
                      placeholder="you@example.com" 
                      onChange={handleEmailChange}
                      required
                      style={{borderBottomColor: errorEmail ? 'var(--error)' : 'var(--acc-blue)'}}
                    />

                    {/* UNAUTHORIZED EMAIL ERROR */}
                    { authError &&
                      <div className="input-error-message">
                        <p className="error-text">This email is not authorized for sign-up.</p>
                      </div>
                    }
                  </div>
                </div>

                <div className="form-buttons">
                  <PrimaryButton 
                    buttonLabel="Check Email"
                    className="button"
                    onClick={handleEmailCheck}
                  />
                </div>
              </form>
            )}
            {/* SIGN IN FORM END */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
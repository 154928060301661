import styles from '../styles/SinglePostPopup.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { auth, db, storage } from '../firebase';
import { doc, getDoc, setDoc, addDoc, deleteDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage'; // Import getDownloadURL and ref

import BubblePill from './BubblePill';
import TertiaryButton from './TertiaryButton';
import SecondaryButton from './SecondaryButton';
import profilePlaceholder from '../assets/profile-placeholder.svg';


const SinglePostPopup = ({ isVisible, onClose, post }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(post.likesCount || 0);
  const [commentCount, setCommentCount] = useState(0);
  const [commentContent, setCommentContent] = useState('');
  const [comments, setComments] = useState([]);

  // Toggle Like
  const toggleLike = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const likesRef = collection(db, 'posts', post.id, 'likes');
        const likeDocRef = doc(likesRef, user.uid);
        const likeDoc = await getDoc(likeDocRef);

        if (likeDoc.exists()) {
          await deleteDoc(likeDocRef);
          setLikeCount((prev) => prev - 1);
          setIsLiked(false);
        } else {
          await setDoc(likeDocRef, {
            userID: user.uid,
            postID: post.id,
            timestamp: serverTimestamp(),
          });
          setLikeCount((prev) => prev + 1);
          setIsLiked(true);
        }
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  // Add Comment
  const addComment = async () => {
    console.log("Post button was clicked");
    try {
      const user = auth.currentUser;
      if (user && commentContent.trim()) {
        console.log("Authenticated user:", user.uid);
        console.log("Content of the comment:", commentContent);

        const commentsRef = collection(db, 'posts', post.id, 'comments');
        const docRef = await addDoc(commentsRef, {
          userID: user.uid,
          postID: post.id,
          timestamp: serverTimestamp(),
          content: commentContent,
        });
        console.log("Comment added successfully with ID:", docRef.id);

        setCommentContent('');
      } else {
        console.warn("No authenticated user or empty comment content.");
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  // Fetch Comments and Comment Count
useEffect(() => {
  if (post.id) {
    const commentsRef = collection(db, 'posts', post.id, 'comments');
    
    const unsubscribeComments = onSnapshot(commentsRef, async (snapshot) => {
      setCommentCount(snapshot.size);
      
      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (commentDoc) => {
          const commentData = commentDoc.data();
          
          if (commentData.userID) {
            const commentUserDocRef = doc(db, 'users', commentData.userID);
            const commentUserDoc = await getDoc(commentUserDocRef);

            if (commentUserDoc.exists()) {
              const commentUserData = commentUserDoc.data();
              let profilePicUrl = commentUserData.profilePic || profilePlaceholder;

              // Check if profilePic is a gs:// URL, and fetch a downloadable URL
              if (profilePicUrl.startsWith('gs://')) {
                const profilePicRef = ref(storage, profilePicUrl);
                profilePicUrl = await getDownloadURL(profilePicRef);
                console.log('profilePicURL:', profilePicUrl)
              }

              return {
                id: commentDoc.id,
                ...commentData,
                commentUserHandle: commentUserData.handle,
                commentUserProfilePic: profilePicUrl,
              };
            } else {
              return {
                id: commentDoc.id,
                ...commentData,
                commentUserHandle: 'Unknown User',
                commentUserProfilePic: profilePlaceholder,
              };
            }
          } else {
            return {
              id: commentDoc.id,
              ...commentData,
              commentUserHandle: 'Unknown User',
              commentUserProfilePic: profilePlaceholder,
            };
          }
        })
      );

      setComments(fetchedComments);
    });

    const likesRef = collection(db, 'posts', post.id, 'likes');
    const unsubscribeLikes = onSnapshot(likesRef, (snapshot) => {
      setLikeCount(snapshot.size);
      setIsLiked(snapshot.docs.some((doc) => doc.id === auth.currentUser?.uid));
    });

    return () => {
      unsubscribeComments();
      unsubscribeLikes();
    };
  }
}, [post.id]);

  if (!isVisible) return null;

  return (
    <div className="single-post-overlay">
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <div className="container-header">
          <Link 
              to={{
                pathname: `/${post.userHandle}`  // Use post.userID dynamically in the URL
              }}
              onClick={() => console.log("Navigating to userID: ", post.userID)}
              style={{ textDecorationLine: 'none' }}
          >
            <p>
              @{post.userHandle}{post.userHandle.endsWith('s') ? "'" : "'s"} post
            </p>
          </Link>
          <div onClick={onClose}>
            <Icon.X size={22} />
          </div>
        </div>

        <hr />

        <div className="post-content">
          <div className="posted-by">
            <div className="posted-by-image">
              <Link 
                to={{
                  pathname: `/${post.userHandle}`  // Use post.userID dynamically in the URL
                }}
                onClick={() => console.log("Navigating to userID: ", post.userID)}
                style={{ textDecorationLine: 'none' }}
              >
                <img src={post.userProfilePic || profilePlaceholder} alt="User Avatar" />
              </Link>
            </div>
            <div className="posted-by-details">
              <div className="posted-by-user">
                <Link 
                  to={{
                    pathname: `/${post.userHandle}`  // Use post.userID dynamically in the URL
                  }}
                  onClick={() => console.log("Navigating to userID: ", post.userID)}
                  style={{ textDecorationLine: 'none' }}
                >
                  <p className="posted-by-name">{post.userHandle}</p>
                </Link>
                <div className="follow-button">
                  <TertiaryButton buttonLabel="+ Follow" />
                </div>
              </div>
              <span className="posted-by-date">{post.timestamp?.toDate().toLocaleString()}</span>
            </div>
          </div>

          <p className="post-body">{post.content}</p>

          <div className="post-actions">
            <div className="post-data-count">
              <span>{likeCount} Likes</span>
              <span> • </span>
              <span>{commentCount} Comments</span>
            </div>
            <div className="like-comment">
              <button onClick={toggleLike} className="like-button">
                <Icon.ThumbsUp 
                  size={21} 
                  fill={isLiked ? '#1D599C' : 'none'} 
                  color={isLiked ? '#0094D4' : '#fff'}
                />
              </button>
              <button className="comment-button">
                <Icon.MessageSquare size={22} />
              </button>
            </div>
          </div>
        </div>

        <hr />

        {/* COMMENT SECTION */}
        <div className="comment-section">
          {comments.map((comment) => (
            <div key={comment.id} className="single-comment">
              <div className="commented-by">
                <div className="commented-by-image">
                  <Link 
                    to={{
                      pathname: `/${comment.commentUserHandle}`  // Use post.userID dynamically in the URL
                    }}
                     onClick={() => console.log("Navigating to userID: ", post.userID)}
                    style={{ textDecorationLine: 'none' }}
                  >
                    <img src={comment.commentUserProfilePic || profilePlaceholder} alt="oops" />
                  </Link>
                </div>
                <div className="commented-by-details">
                  <div className="commented-by-user">
                    <Link 
                      to={{
                        pathname: `/${comment.commentUserHandle}`  // Use post.userID dynamically in the URL
                      }}
                      onClick={() => console.log("Navigating to userID: ", post.userID)}
                      style={{ textDecorationLine: 'none' }}
                    >
                      <p className="commented-by-name">@{comment.commentUserHandle || 'Unknown User'}</p>
                    </Link>
                    <span className="commented-by-date">
                      {comment.timestamp?.toDate ? comment.timestamp.toDate().toLocaleString() : 'N/A'}
                    </span>
                  </div>
                  <div className="comment-body">
                    <p>{comment.content}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <hr className="divider-bottom" />

        {/* CREATE COMMENT SECTION */}
        <div className="create-comment">
          <div className="comment-user-image">
            <img src={profilePlaceholder} alt="User Profile" />
          </div>
          <textarea
            className="post-content"
            placeholder="Write a comment..."
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
          />
          <div className="post-comment-button">
            <SecondaryButton buttonLabel="Post" onClick={() => {
              console.log("SecondaryButton onClick triggered");
              addComment();
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePostPopup;
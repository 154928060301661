import styles from '../styles/UserCard.css';
import * as Icon from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import SecondaryButton from './SecondaryButton';
import { db, auth } from '../firebase'; // Ensure Firebase is imported
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

const UserCard = ({ user }) => {
  if (!user) {
    return null; // Return nothing if user data is not available
  } else {
    console.log("Pulled data for user: ", user);
  }

  const handleFollow = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          following: arrayUnion(user.uid) // Add the UID of the suggested user
        });
        console.log(`Successfully followed user with UID: ${user.uid}`);
      } else {
        console.error('No authenticated user found');
      }
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  return (
    <div className="user-card component">
      <div className="user-card-container">
        <div className="user-image-card">
          <Link 
            to={{
              pathname: `/${user.handle}`
            }} 
            style={{ textDecorationLine: 'none' }}
          >
            <img src={user.profilePic || profilePlaceholder} alt={`${user.userName || 'User'}'s profile`} />
          </Link>
        </div>
          <Link 
            to={{
              pathname: `/${user.handle}`
            }} 
            style={{ textDecorationLine: 'none' }}
          >
            <p className="user-name-card">{user.userName || "User's Name"}</p>
          </Link>
          <Link 
            to={{
              pathname: `/${user.handle}`
            }} 
            style={{ textDecorationLine: 'none' }}
          >
             <p className="user-handle-card">@{user.handle || 'userhandle'}</p>
          </Link>
       
        <div className="button">
          <SecondaryButton buttonLabel="FOLLOW" onClick={handleFollow} />
        </div>
      </div>
    </div>
  );
};

export default UserCard;
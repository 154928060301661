import styles from '../styles/Follows.css';
import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'; 
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { auth, db, storage } from '../firebase';
import { getDownloadURL, ref } from "firebase/storage";

import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

const Follows = ({ passedUID }) => {
  const [isFollowersActive, setIsFollowersActive] = useState(true);
  const [isFollowingActive, setIsFollowingActive] = useState(false);

  const [authFollowers, setAuthFollowers] = useState([]);
  const [authFollowing, setAuthFollowing] = useState([]);
  const [viewFollowers, setViewFollowers] = useState([]);
  const [viewFollowing, setViewFollowing] = useState([]);

  const [authFollowersCount, setAuthFollowersCount] = useState(0);
  const [authFollowingCount, setAuthFollowingCount] = useState(0);
  const [viewFollowersCount, setViewFollowersCount] = useState(0);
  const [viewFollowingCount, setViewFollowingCount] = useState(0);

  const authUser = auth.currentUser;

  const toggleView = () => {
    setIsFollowersActive((prev) => !prev);
    setIsFollowingActive((prev) => !prev);
  };

  const handleFollow = async (followUserId) => {
    if (!authUser) return;
  
    try {
      const authUserDocRef = doc(db, 'users', authUser.uid);
  
      await updateDoc(authUserDocRef, {
        following: arrayUnion(followUserId),
      });
  
      setAuthFollowing((prevFollowing) => [
        ...prevFollowing,
        { userId: followUserId, profilePic: '', userName: '', handle: '' },
      ]);
      setAuthFollowingCount((prevCount) => prevCount + 1);
  
      console.log(`Now following user: ${followUserId}`);
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const handleUnfollow = async (unfollowUserId) => {
    if (!authUser) return;
  
    try {
      const userDocRef = doc(db, 'users', authUser.uid);
  
      await updateDoc(userDocRef, {
        following: arrayRemove(unfollowUserId),
      });
  
      setAuthFollowing((prevFollowing) => prevFollowing.filter((follow) => follow.userId !== unfollowUserId));
      setAuthFollowingCount((prevCount) => prevCount - 1);
    } catch (error) {
      console.error('Error unfollowing user:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async (uid, setFollowers, setFollowing, setFollowersCount, setFollowingCount) => {
      try {
        const userDocRef = doc(db, 'users', uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFollowersCount(userData.followers?.length || 0);
          setFollowingCount(userData.following?.length || 0);
          await fetchDetails(userData.followers || [], userData.following || [], setFollowers, setFollowing);
        } else {
          console.log('No such document for user:', uid);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchDetails = async (followerIds, followingIds, setFollowers, setFollowing) => {
      const fetchUserDetails = async (id) => {
        const userDocRef = doc(db, 'users', id);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const { userName, handle, profilePic, submergeID } = userDoc.data();
          let profilePicURL = profilePic || '../assets/avatar.jpg';
          if (profilePic && profilePic.startsWith('gs://')) {
            const storageRef = ref(storage, profilePic);
            profilePicURL = await getDownloadURL(storageRef);
          }
          return { userName, handle, profilePic: profilePicURL, userId: id, submergeID };
        }
        return null;
      };

      const followerDetails = await Promise.all(followerIds.map(fetchUserDetails));
      const followingDetails = await Promise.all(followingIds.map(fetchUserDetails));

      setFollowers(followerDetails.filter(detail => detail !== null));
      setFollowing(followingDetails.filter(detail => detail !== null));
    };
       
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData(user.uid, setAuthFollowers, setAuthFollowing, setAuthFollowersCount, setAuthFollowingCount);
      }
    });

    if (passedUID) {
      fetchUserData(passedUID, setViewFollowers, setViewFollowing, setViewFollowersCount, setViewFollowingCount);
    }

    return () => unsubscribe();
  }, [passedUID]);

  useEffect(() => {
    console.log("authFollowers:", authFollowers);
    console.log("authFollowing:", authFollowing);
    console.log("viewFollowers:", viewFollowers);
    console.log("viewFollowing:", viewFollowing);
  }, [authFollowers, authFollowing, viewFollowers, viewFollowing]);

  return (
    <div className="user-follows">
      <div className="follows-tabs">
        <div className="tab-item" onClick={toggleView}>
          <div className="count">
            <span className="count-number">{viewFollowersCount}</span>
            <span className="count-label">Followers</span>
          </div>
          <div className="tab-underline" style={{ backgroundColor: isFollowersActive ? 'var(--acc-blue)' : 'transparent' }}></div>
        </div>

        <div className="tab-item" onClick={toggleView}>
          <div className="count">
            <span className="count-number">{viewFollowingCount}</span>
            <span className="count-label">Following</span>
          </div>
          <div className="tab-underline" style={{ backgroundColor: isFollowingActive ? 'var(--acc-blue)' : 'transparent' }}></div>
        </div>
      </div>

      {/* Rendering Followers or Following List Based on State */}
      <div className="user-follows-list">
        {isFollowersActive && viewFollowers.map((follower, index) => {
          const isFollowingAuthUser = authFollowing.some(follow => follow.userId === follower.userId);

          return (
            <div key={index} className="user-follows-card">
              <div className="image-name">
                <div className="user-image-card">
                  <img src={follower.profilePic} alt={follower.userName} />
                </div>
                <div>
                <Link
                    to={`/${follower.handle}`}
                    onClick={() => console.log('Navigating to userID:', follower.userId)}
                    style={{ textDecoration: 'none' }}
                  >
                    <p className="user-name-card">{follower.userName}</p>
                  </Link>
                  <Link
                    to={`/${follower.handle}`}
                    onClick={() => console.log('Navigating to userID:', follower.userId)}
                    style={{ textDecoration: 'none' }}
                  >
                    <p className="user-handle-card">@{follower.handle}</p>
                  </Link>
                  
                </div>
              </div>
              <div className="button">
                {follower.userId === authUser.uid ? (
                  <SecondaryButton buttonLabel="YOU" disabled />
                ) : isFollowingAuthUser ? (
                  <SecondaryButton buttonLabel="FOLLOWING" disabled />
                ) : (
                  <PrimaryButton buttonLabel="FOLLOW" onClick={() => handleFollow(follower.userId)} />
                )}
              </div>
            </div>
          );
        })}

        {isFollowingActive && viewFollowing.map((follow, index) => {
          const isAlreadyFollowing = authFollowing.some(f => f.userId === follow.userId);

          return (
            <div key={index} className="user-follows-card">
              <div className="image-name">
                <div className="user-image-card">
                  <img src={follow.profilePic} alt={follow.userName} />
                </div>
                <div>
                  <Link
                    to={`/${follow.handle}`}
                    onClick={() => console.log('Navigating to userID:', follow.userId)}
                    style={{ textDecoration: 'none' }}
                  >
                    <p className="user-name-card">{follow.userName}</p>
                  </Link>
                  <Link
                    to={`/${follow.handle}`}
                    onClick={() => console.log('Navigating to userID:', follow.userId)}
                    style={{ textDecoration: 'none' }}
                  >
                    <p className="user-handle-card">@{follow.handle}</p>
                  </Link>
                  
                </div>
              </div>
              <div className="button">
                {follow.userId === authUser.uid ? (
                  <SecondaryButton buttonLabel="YOU" disabled />
                ) : isAlreadyFollowing ? (
                  <SecondaryButton buttonLabel="FOLLOWING" disabled />
                ) : (
                  <PrimaryButton buttonLabel="FOLLOW" onClick={() => handleUnfollow(follow.userId)} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Follows;
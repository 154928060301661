import { useEffect, useState } from "react";
import NewsfeedCard from '../components/NewsfeedCard';
import CreatePost from '../components/CreatePost';
import ProfilePanel from '../components/ProfilePanel';
import TopBubbles from '../components/TopBubbles';
import DiscoverPeoplePanel from '../components/DiscoverPeoplePanel';
import DiscoverPeopleSlider from '../components/DiscoverPeopleSlider';
import Header from '../components/Header';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import styles from '../styles/MainScreen.css';

function MainScreen() {
  const [isTrendingActive, setIsTrendingActive] = useState(true);
  const [isFollowingActive, setIsFollowingActive] = useState(false);
  const [trendingTopic, setTrendingTopic] = useState("artist");
  const [userData, setUserData] = useState(null);

  const toggleView = () => {
    setIsTrendingActive((prev) => !prev);
    setIsFollowingActive((prev) => !prev);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserData({ uid: user.uid, ...userDoc.data() });
          } else {
            console.log('User document does not exist');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="mainscreen">
      <Header 
        avatar="true" 
        radioButton="true"
        showLogo="true"
      />

      <main className="mainscreen-content">
        <div className="profile-pane">
          <ProfilePanel userData={userData} />
        </div>

        <div className="feed">
          <div className="create-post-section">
            <CreatePost userData={userData} />
          </div>

          <div className="newsfeed-tabs">
            <div className="tab-item" onClick={toggleView}>
              <div className="label">
                <button className="tab-button">
                  <span 
                    style={{ color: isTrendingActive ? 'var(--acc-blue)' : 'var(--text-grey)' }}>
                    Trending For You
                  </span>
                </button>
              </div>
              <div className="tab-underline" 
                   style={{ backgroundColor: isTrendingActive ? 'var(--acc-blue)' : 'transparent' }}>
              </div>
            </div>

            <div className="tab-item" onClick={toggleView}>
              <div className="label">
                <button className="tab-button">
                  <span 
                    style={{ color: isFollowingActive ? 'var(--acc-blue)' : 'var(--text-grey)' }}>
                    Following
                  </span>
                </button>
              </div>
              <div className="tab-underline" 
                   style={{ backgroundColor: isFollowingActive ? 'var(--acc-blue)' : 'transparent' }}>
              </div>
            </div>
          </div>

          {userData && (
            <NewsfeedCard 
              userUID={userData.uid} // Pass the authenticated user's UID to NewsfeedCard
              trendingTopic={trendingTopic}
              {...(isTrendingActive ? { isTrendingActive } : {})}
              {...(isFollowingActive ? { isFollowingActive } : {})}
            />
          )}
          
          <DiscoverPeopleSlider />
        </div>

        <div className="web-right-panel">
          <TopBubbles setTrendingTopic={setTrendingTopic} />
          {userData && <DiscoverPeoplePanel userData={userData} />}
        </div>
      </main>
    </div>
  );
}

export default MainScreen;
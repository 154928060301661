import styles from '../styles/Messages.css'
import { useEffect, useState } from 'react';
import { auth, db, storage } from '../firebase';
import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import Header from '../components/Header';
import CreateMessageModal from '../components/CreateMessageModal';
import * as Icon from 'react-feather';
import profilePlaceholder from '../assets/profile-placeholder.svg';

const Messages = () => {
  const [isCreateMessageVisible, setIsCreateMessageVisible] = useState(false);
  const [isChatOptionsVisible, setIsChatOptionsVisible] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [otherUserData, setOtherUserData] = useState({});  
  const [currentUserProfilePic, setCurrentUserProfilePic] = useState(profilePlaceholder);
  const currentUser = auth.currentUser;

  // Define toggle functions
  const toggleCreateMessagePopup = () => setIsCreateMessageVisible(!isCreateMessageVisible);
  const toggleChatOptionsModal = () => setIsChatOptionsVisible(!isChatOptionsVisible);

  useEffect(() => {
    // Fetch current user's profile picture
    const fetchCurrentUserProfilePic = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            let profilePicUrl = userDoc.data().profilePic || profilePlaceholder;
            if (profilePicUrl.startsWith('gs://')) {
              const storageRef = ref(storage, profilePicUrl);
              profilePicUrl = await getDownloadURL(storageRef);
            }
            setCurrentUserProfilePic(profilePicUrl);
          }
        } catch (error) {
          console.error('Error fetching current user profile picture:', error);
        }
      }
    };

    fetchCurrentUserProfilePic();
  }, [currentUser]);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const chatsRef = collection(db, 'chats');
        const q = query(chatsRef, where('participants', 'array-contains', currentUser.uid));
        const querySnapshot = await getDocs(q);

        const fetchedChats = querySnapshot.docs.map((doc) => ({
          chatId: doc.id,
          ...doc.data(),
        }));
        setChats(fetchedChats);

        if (fetchedChats.length > 0) {
          setSelectedChat(fetchedChats[0]);
        }

        const otherUserDataMap = {};
        for (const chat of fetchedChats) {
          const otherUserId = chat.participants.find((id) => id !== currentUser.uid);
          if (otherUserId && !otherUserDataMap[otherUserId]) {
            const userDoc = await getDoc(doc(db, 'users', otherUserId));
            if (userDoc.exists()) {
              const { profilePic, handle } = userDoc.data();
              let profilePicUrl = profilePic || profilePlaceholder;
              if (profilePicUrl.startsWith('gs://')) {
                const storageRef = ref(storage, profilePicUrl);
                profilePicUrl = await getDownloadURL(storageRef);
              }
              otherUserDataMap[otherUserId] = { profilePic: profilePicUrl, handle };
            } else {
              otherUserDataMap[otherUserId] = { profilePic: profilePlaceholder, handle: 'Unknown User' };
            }
          }
        }
        setOtherUserData(otherUserDataMap);
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    };

    fetchChats();
  }, [currentUser]);

  // Fetch messages for the selected chat
  const fetchMessages = async () => {
    if (!selectedChat?.chatId) return;

    try {
      const messagesRef = collection(db, 'messages');
      const q = query(messagesRef, where('chatId', '==', selectedChat.chatId), orderBy('timestamp'));
      const querySnapshot = await getDocs(q);

      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        messageId: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [selectedChat]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedChat) return; // Prevent empty message or no selected chat
  
    try {
      // Add the new message to the messages collection and get its reference
      const messageRef = await addDoc(collection(db, 'messages'), {
        chatId: selectedChat.chatId,
        senderId: currentUser.uid,
        content: newMessage.trim(),
        timestamp: serverTimestamp(),
        isReadBy: [],
        messageType: 'text',
      });
  
      // Update the lastUpdated and lastMessageId fields in the chats collection
      await updateDoc(doc(db, 'chats', selectedChat.chatId), {
        lastUpdated: serverTimestamp(),
        lastMessageId: messageRef.id, // Set lastMessageId to the new message ID
      });
  
      setNewMessage(''); // Clear the input after sending
  
      // Re-fetch messages to include the newly sent message
      await fetchMessages(); // Call fetchMessages here
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="messages component">
    	<div className="messages-container">
      	<Header goBack pageTitle="Messages" />

        <div className="messages-main">

          <div className="messages-list">
            <div className="messages-list-header">
              <input className="search-bar" type="text" placeholder="Search" />
              <div className="write-icon" onClick={toggleCreateMessagePopup}>
                <Icon.Edit size={20} />
              </div>
            </div>

            {/* CHAT LIST */}
            {chats.map((chat) => {
              const otherUserId = chat.participants.find((id) => id !== currentUser.uid);
              const { profilePic, handle } = otherUserData[otherUserId] || { profilePic: profilePlaceholder, handle: 'Unknown User' };

              return(
                <div 
                  key={chat.chatId} 
                  className="message-item" 
                  onClick={() => {
                    console.log("Selected chat:", chat);
                    setSelectedChat(chat);
                  }}
                >
                  <div className="image-and-content">
                    <div className="user-image">
                      <img src={profilePic} alt="Profile" />
                    </div>
                    <div className="message-content">
                      <p className="recipient-name">
                        {chat.isGroupChat === true ? (chat.groupName || 'Unnamed Group') : handle}
                      </p>
                      {/* <p className="message-preview">message preview</p> */}
                    </div>
                  </div>
                  <div className="time-and-indicator">
                    <p className="time-ago">now</p>
                    <div style={{ width: '8px', height: '8px', backgroundColor: 'var(--acc-blue)', borderRadius: '50%' }}></div>
                  </div>
                </div>
              );
              
            })}
          </div>
          
          {/* SELECTED CONVERSATION */}
          <div className="selected-conversation">
            {selectedChat ? (
              <>
                <div className="conversation-header">
                  <p className="conversation-title">
                    {selectedChat.isGroupChat === true
                    ? selectedChat.groupName
                    : otherUserData[selectedChat.participants.find((id) => id !== currentUser.uid)]?.handle || 'Unknown User'}
                  </p>
                  <div className="more-icon" onClick={toggleChatOptionsModal}>
                    <Icon.MoreVertical size={20} />
                  </div>

                  {/* OPTIONS MODAL */}
                  {isChatOptionsVisible && (
                    <div className="chat-options-modal">
                      <ul className="chat-options-list">
                        <li>Delete Conversation</li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* MESSAGES */}
                <div className="conversation">
                  <>
                      <div className="bubbles">
                        {messages.length > 0 ? (
                          messages.map((message) => (
                            <div
                              key={message.messageId}
                              className={`${message.senderId === currentUser.uid ? 'outgoing-message' : 'incoming-message'} message-row`}
                            >
                              {message.senderId !== currentUser.uid ? (
                                <div className="incoming-message message-row">
                                  <div className="user-image">
                                    <img
                                      src={otherUserData[message.senderId]?.profilePic || profilePlaceholder}
                                      alt="Sender Profile"
                                    />
                                  </div>
                                  <div className="incoming-message-bubble">
                                    <p className="message-body" style={{ whiteSpace: 'pre-wrap '}}>{message.content}</p>
                                    <p className="time">
                                    {message.timestamp?.toDate().toLocaleDateString('en-GB')} {' | '}  
                                    {message.timestamp?.toDate().toLocaleTimeString('en-US', {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true
                                    })}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="outgoing-message message-row">
                                  <div className="outgoing-message-bubble">
                                  <p className="message-body" style={{ whiteSpace: 'pre-wrap '}}>{message.content}</p>
                                    <p className="time">
                                      {message.timestamp?.toDate().toLocaleDateString('en-GB')} {' | '}  
                                      {message.timestamp?.toDate().toLocaleTimeString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                      })}
                                    </p>
                                  </div>
                                  <div className="user-image">
                                    <img src={currentUserProfilePic} alt="Your Profile" />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="empty-conversation">
                            <p className="empty-message">No messages in this conversation.</p>
                          </div>
                        )}

                        <div className="compose-message-section">
                          <div className="compose-message-content">
                            <textarea
                              className="compose-message"
                              placeholder="Aa"
                              value={newMessage}
                              onChange={(e) => setNewMessage(e.target.value)}
                            />
                            <div className="send-icon" onClick={handleSendMessage}>
                              <Icon.Send size={20} color="var(--acc-blue)" />
                            </div>
                            <div className="attachments">
                              <div className="image-attachment attachment-icon">
                                <Icon.Image size={20} color="var(--acc-blue)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> {/* closing div for bubbles */}
                  </>       
                </div> {/* closing div for conversation */}
              </>) : 
              ( 
                <div className="empty-conversation">
                  <p className="empty-message">
                    Start a conversation by clicking on the &nbsp;
                    <span> <Icon.Edit size={16} color="var(--faded-white)" style={{marginBottom: '-3px'}}/> </span> &nbsp; icon.
                  </p>
                </div>
              )
            }
 
          </div>

        </div> {/* closing div for messages main */}
    	</div>

      <div className="popup">
        <CreateMessageModal isVisible={isCreateMessageVisible} onClose={toggleCreateMessagePopup} />
      </div>
    </div>
  );
}

export default Messages;

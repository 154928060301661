import styles from '../styles/Header.css'
import * as Icon from 'react-feather';
import radioIcon from '../assets/radio-icon.svg'
import submergeLogo from '../assets/submerge-logo.png'
import { useEffect, useState, useRef } from "react";
import userAvatar from '../assets/avatar.jpg'
import NotificationPopup from '../components/NotificationPopup'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


const Header = ({goBack, pageTitle, avatar, radioButton, showLogo}) => {

  const [isNotifOpen, setIsNotifOpen] = useState(false);
  const contentRef = useRef(null);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  }

  const toggleNotifModal = () => {
    setIsNotifOpen(prevState => !prevState);
  }

  const handleClickOutside = (event) => {
    // If the click happens outside the content, close it
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      setIsNotifOpen(false);
    }
  };

  useEffect(() => {
    if (isNotifOpen) {
      // Add event listener to detect outside clicks when content is visible
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Clean up the event listener when content is hidden
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotifOpen]);


  return (
    <div className="header">
    	<header className="header-mobile">

        <Link to={handleGoBack} style={{display: radioButton ? 'block' : 'none'}}>
          <img src={radioIcon} />
        </Link>

        <Link to="/" style={{display: goBack ? 'block' : 'none'}}>
          <Icon.ChevronLeft style={{margin: 'auto'}}/>
        </Link>

    		<h5 style={{ margin: 'auto', textTransform : 'uppercase'}}>{pageTitle}</h5>

    		<div className="right-buttons">
    			<Link to="/notifications" className="notif-button-mobile">
            <Icon.Bell size={20} style={{margin: 'auto'}}/>
          </Link>
          <Link to="/messages" className="messages-button-mobile">
            <Icon.MessageCircle size={20} style={{margin: 'auto'}}/>
          </Link>
    			<img src={userAvatar} className="header-avatar" style={{display: avatar ? 'block' : 'none'}}/>
    		</div>

    	</header>

      <header className="header-web">

        <div className="live-radio-button" style={{display: radioButton ? 'block' : 'none'}}>
          <img src={radioIcon} />
        </div>

        <Link to="/" style={{display: goBack ? 'block' : 'none'}}>
          <Icon.ChevronLeft style={{margin: 'auto'}}/>
        </Link>

        <Link to="/">
          <img className="submerge-logo" src={submergeLogo} style={{ display: showLogo ? 'block' : 'none', margin: '0 auto' }}/>
          <h5 style={{ margin: 'auto', textTransform :'uppercase', display: pageTitle ? 'block' : 'none'}} >
            {pageTitle}
          </h5>
        </Link>

        <div className="right-buttons-web" ref={contentRef}>
          <Link to="/messages" className="messages-button-mobile right-button">
            <Icon.MessageCircle />
          </Link>
          <div className="open-notif-modal right-button" onClick={toggleNotifModal}>
            <Icon.Bell />
          </div>
          

          { isNotifOpen && 
            <div className="notification-popup-modal">
              <NotificationPopup />
            </div>
          }
          
        </div>


        {/*<Icon.ChevronLeft style={{display: goBack ? 'block' : 'none'}}/>*/}
      </header>
    </div>
  );
}

export default Header;